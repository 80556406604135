import React from "react";
import AppBar from '@mui/material/AppBar';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./../firebaseConfig";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function Navbar() {
  const [user] = useAuthState(auth);
  return (
      <Box>
      <AppBar style={{ background: '#fff' }}>
        <Button href="https://nevarezmarketing.com">
          Home{" "}
        </Button>
      </AppBar>
    </Box>
  );
}
