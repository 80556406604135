import {initializeApp} from 'firebase/app'
import { getAuth } from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';
 

const firebaseConfig = {
  apiKey: "AIzaSyCOO2o6oKnAtx4IoG1LvaO10W2i57mzZi4",
  authDomain: "blog-4dcef.firebaseapp.com",
  databaseURL: "https://blog-4dcef-default-rtdb.firebaseio.com",
  projectId: "blog-4dcef",
  storageBucket: "blog-4dcef.appspot.com",
  messagingSenderId: "570489897439",
  appId: "1:570489897439:web:931b52a5c556123163046a",
  measurementId: "G-ESB8NL9ZKG"
  };

  const app = initializeApp(firebaseConfig);

  export const storage = getStorage(app);
  export const db = getFirestore(app);
  export const auth =getAuth(app);

 
 