import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import DeleteArticle from "./DeleteArticle";
import { useAuthState } from "react-firebase-hooks/auth";
import LikeArticle from "./LikeArticle";
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

export default function Articles() {
  const [articles, setArticles] = useState([]);
  const [user] = useAuthState(auth);
  useEffect(() => {
    const articleRef = collection(db, "Articles");
    const q = query(articleRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const articles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArticles(articles);
      console.log(articles);
    });
  }, []);
  return (
    <div>
      {articles.length === 0 ? (
      
        <p>No articles found!</p>
      ) : (
        articles.map(
          ({
            id,
            title,
            description,
            imageUrl,
            createdAt,
            createdBy,
            userId,
            likes,
            comments,
          }) => (
           
            <Card elevation={16} key={id}>
            
              <div className="row">
              <CardHeader
        avatar={
          <Avatar>
            NM
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
             <div className="col-6 d-flex flex-row-reverse">
                      {user && user.uid === userId && (
                        <DeleteArticle id={id} imageUrl={imageUrl} />
                      )}
                    </div>
          </IconButton>
        }
        title={title}
        subheader={createdAt.toDate().toDateString()}
      />
                  <Link to={`/article/${id}`}>
                    <CardMedia
                     component="img"   
                     src={imageUrl}
                     title="Click to View"
                     height="194"
                     />
                  </Link>
                
                <CardContent>
        <Typography variant="body2" color="text.secondary">
        <h5>{description}</h5>
        </Typography>
      </CardContent>
                <div className="">
                  <div className="row container">
                 
                      {createdBy && (
                        <span className="">Author: {createdBy}</span>
                      )}
                     
                  
                  </div>

 
                  <div className="d-flex flex-row-reverse">
                    {user && <LikeArticle id={id} likes={likes} />}
                    <div className="pe-2">
                      <p>{likes?.length} likes</p>
                    </div>
                    {comments && comments.length > 0 && (
                      <div className="pe-2">
                        <p>{comments?.length} comments</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          )
        )
      )}
    </div>
  );
}